import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../../styles/SideBar.module.scss';
import { IoMdClose } from "react-icons/io";
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { closeSidebar, selectSideBarExpanded } from '../../features/sideBar/sideBarExpendedSlice';
import { SubMenu } from './SubMenu';
import { GrMoney } from "react-icons/gr";
import { MdOutlineNewReleases } from "react-icons/md";
import { TbLogout2, TbReportAnalytics } from "react-icons/tb";
import { FaRegUser } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { RiRobot2Line } from "react-icons/ri";
import Logo from '../../assets/images/logo.png';
import { logOut, selectCurrentUser } from '../../features/auth/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { RiKey2Line } from "react-icons/ri";
import { DEFAULT_PAGE, LINKS } from '../../config';
import { useEffect, useState } from 'react';
import { SiGoogleclassroom } from "react-icons/si";
import { ITutorial } from '../../interfaces/ITutorial';
import { RiBankLine } from "react-icons/ri";
import axios from "axios";
import { BsPerson } from 'react-icons/bs';
import { IoCodeSlash } from 'react-icons/io5';
import { FiArchive, FiBarChart2, FiFileText } from 'react-icons/fi';
import { CiGlobe } from 'react-icons/ci';
import { FaRegMessage } from "react-icons/fa6";

export const SideBar = () => {
  const [tutoriais, setTutoriais] = useState<ITutorial[]>([]);
  const expanded = useSelector(selectSideBarExpanded);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`${LINKS.API}/tutorials/get/sidebar`).then((response) => {
      if (response.data.status === 200) {
        setTutoriais(response.data.data);
      } else if (response.data.status === 401) {
        dispatch(logOut());
        const redirectTo = encodeURIComponent(window.location.pathname);
        navigate(`/login?redirect=${redirectTo}`);;
      }
    })
  }, [currentUser, dispatch, navigate]);
  useEffect(() => {
    if (expanded) {
      document.body.classList.add('expanded');
    } else {
      document.body.classList.remove('expanded');
    }
  }, [expanded]);

  const handleCloseSideBar = () => {
    dispatch(closeSidebar());
  };

  return (
    <>
      <div className={classNames(styles.sideBar, expanded && styles.expanded)}>
        <div className={styles.header}>
          <Link to={DEFAULT_PAGE}><img src={Logo} alt="Logo" className={styles.logo} /></Link>
          <IoMdClose className={styles.nav_btn} onClick={handleCloseSideBar}></IoMdClose>
        </div>
        <div className={styles.body}>
          <SubMenu
            titulo="DIA A DIA"
            subMenu={[
              {
                nome: "Produção",
                link: "/producao",
                icon: <GrMoney className={styles.icon} />
              },
              {
                nome: "Comunicados",
                link: "/comunicados",
                icon: <MdOutlineNewReleases className={styles.icon} />
              },
              {
                nome: "Automações",
                link: "/automacoes",
                icon: <RiRobot2Line className={styles.icon} />,
                permissao: ["admin", "operacional"]
              },
              {
                nome: "Acessos",
                link: "/acessos",
                icon: <RiKey2Line className={styles.icon} />,
              },
              {
                nome: "Bancos",
                link: "/bancos",
                icon: <RiBankLine className={styles.icon} />
              },
              // {
              //   nome: "Leads",
              //   link: "/leads",
              //   icon: <BsPerson className={styles.icon} />,
              //   permissao: ["admin"]
              // },
              {
                nome: "Bases",
                link: "/bases",
                icon: <FiBarChart2 className={styles.icon} />,
              },
              {
                nome: "Evidências",
                link: "/evidencias",
                icon: <FaRegMessage className={styles.icon} />
              },
              ...(tutoriais.length > 0 ?
                [{
                  nome: "Tutoriais",
                  link: "#",
                  icon: <SiGoogleclassroom className={styles.icon} />,
                  dropdown: true,
                  filhos: tutoriais.map((tutorial: ITutorial) => {
                    return {
                      nome: tutorial.name,
                      link: `/tutoriais/${tutorial.slug}`
                    }
                  })
                }]
                : []),


            ]}
          />
          <SubMenu
            titulo="gestão"
            permissao={["admin", "operacional"]}
            subMenu={[
              {
                nome: "Usuários",
                link: "/usuarios",
                icon: <FaUsers className={styles.icon} />,
                permissao: ["admin"]
              },
              {
                nome: "Relatórios Diários",
                link: "/relatorios-diarios",
                icon: <TbReportAnalytics className={styles.icon} />,
              },
              {
                nome: "Gerenciar tutoriais",
                link: "/gerenciar-tutoriais",
                icon: <SiGoogleclassroom className={styles.icon} />,
                permissao: ["admin"]
              },
              {
                nome: "Gerenciar Bancos",
                link: "/gerenciar-bancos",
                icon: <RiBankLine className={styles.icon} />,
                permissao: ["admin"]
              },
              {
                nome: "Equipes",
                link: "/equipes",
                icon: <FaUsers className={styles.icon} />,
                permissao: ["admin"]
              },
              {
                nome: "Clientes",
                link: "/clientes",
                icon: <BsPerson className={styles.icon} />,
              },
              {
                nome: "Estoque",
                link: "/estoque",
                icon: <FiArchive className={styles.icon} />,
                permissao: ['admin']
              },
              {
                nome: "Site",
                link: "#",
                icon: <CiGlobe className={styles.icon} />,
                dropdown: true,
                filhos: [
                  // {
                  //   nome: "Serviços",
                  //   link: "/site/servicos"
                  // },
                  {
                    nome: "Operações",
                    link: "/site/operacoes"
                  }
                ]
              },
              {
                nome: "Logs",
                link: "/logs",
                icon: <FiFileText className={styles.icon} />,
                permissao: ['admin']
              },
              {
                nome: "Config. Desenvolvimento",
                link: "/developer",
                icon: <IoCodeSlash className={styles.icon} />,
                permissao: ["admin"]
              }
            ]}
          />
          <SubMenu
            titulo="perfil"
            subMenu={[
              {
                nome: "Meu Perfil",
                link: "/perfil",
                icon: <FaRegUser className={styles.icon} />
              },
              {
                nome: "Sair",
                link: "/logout",
                icon: <TbLogout2 className={styles.icon} />,
                onClick: () => {
                  dispatch(logOut());
                  const redirectTo = encodeURIComponent(window.location.pathname);
                  navigate(`/login?redirect=${redirectTo}`);;
                }
              },
            ]}
          />
        </div>
      </div>
      <div onClick={handleCloseSideBar} className={classNames(styles.backdrop, expanded && styles.backdrop_active)}>
      </div>
    </>
  );
};
